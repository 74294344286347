@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Helvetica';
	src: url('./fonts/Helvetica.dfont');
	src: url;
}

@font-face {
	font-family: 'BigCaslon';
	src: url('./fonts/BigCaslon.ttf');
	src: url;
}

* {
	--lightestGreen: #D3E9E5;
	--lightGreen: #C9E3DE;
	--darkestGreen: #1C8270;
	/*
	--lightestGreen: rgb(206, 223, 219);
	--lightGreen: rgb(197, 210, 206);
	--darkestGreen: rgb(34, 142, 125);
	*/
}

body {
	margin: 0;
	font-family: 'Helvetica', 'sans-serif';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	height: 100%;
	min-width: 100vw;
	min-height: 100vh;
	background-color: var(--lightestGreen);
	overflow-x: hidden;
}



.quote-container {
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	padding: 35px 0;
	color: white;
}

.quote-text {
	font-family: 'BigCaslon';
	font-size: 35px;
	text-align: center;
}

.header-list>li {
	cursor: pointer;
}

.text-holder {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 60%;
	margin-right: 20%;
	font-size: 16px;
}

.text-holder strong {
	text-transform: uppercase;
}

.workrange-label {
	color: #228F7D;
	margin-left: auto;
	font-weight: bold;
}

.contact-building {
	position: absolute;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	object-fit: cover;
	z-index: -1;
	object-position: left 0px;
	top: 0;
	left: 0;
	pointer-events: none;
}

.contact-text {
	line-height: 28px;
	background: rgb(12, 77, 65);
	background: linear-gradient(90deg, rgba(12, 77, 65, 0.7) 16%, rgba(12, 77, 65, 0.8) 54%, rgba(20, 122, 104, 1) 87%);
}

.header-list.gradient {
	background: rgb(163, 174, 175);
	background: linear-gradient(90deg, rgba(163, 174, 175, 0.2) 12%, rgba(163, 174, 175, 0.3) 34%, rgba(163, 174, 175, 0.4) 61%, rgba(197, 212, 207, 0.6) 87%);
}

.logo-holder label {
	font-family: 'BigCaslon';
	text-align: center;
	letter-spacing: 3px;
	margin-top: 5px;
	line-height: 18px;
}

.logo-holder strong {
	font-size: 13px;
	letter-spacing: 3px;
}

.site-content {
	margin: 0 auto;
	width: 60%;
	padding: 50px 0;
}

.site-content>.text-holder {
	margin-right: 0;
	width: 100%;
}

.workrange-content {
	padding: 50px 0;
	width: 90%;
	margin: 0 auto;
}

.header-list {
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 2px;
	height: 61px;
	opacity: 1;
	transition: all .5s ease-in-out;
}

.menu-icon {
	position: absolute;
	left: 40px;
	top: 50px;
	opacity: 0;
	pointer-events: none;
	transition: opacity .5s ease-in-out;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: var(--lightGreen);
	z-index: 10;
	pointer-events: none;
	transition: width .5s ease-in-out;
	overflow: hidden;
	white-space: nowrap;
}

.overlay.active {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(32, 33, 36, .6);
	z-index: 9;
}

.modal.active {
	pointer-events: all;
	width: 250px;
}

.modal-list>li.active,
.header-list>li.active {
	color: var(--darkestGreen);
}

@media screen and (max-width: 1000px) {
	.menu-icon {
		opacity: 1;
		pointer-events: all;
		cursor: pointer;
	}

	.workrange-content {
		display: flex;
		flex-direction: column;
		gap: 50px;
	}

	.workrange-content>section {
		flex-direction: column;
		width: 80%;
		margin: 0 auto;
	}

	.text-holder {
		width: 100%;
	}

	.workrange-label {
		margin-left: 0;
	}

	.site-content {
		width: 80%;
	}

	.header-list {
		opacity: 0;
		height: 0;
		padding: 0;
	}
}



@media screen and (max-width: 700px) {

	.quote-text {
		font-size: 25px;
	}

	.contact-text {
		font-size: 20px;
		padding: 48px 0;
	}
}